@import '../../styles/common';

// Carousel
// ----------------------

.@{ns}carousel {
  position: relative;
  height: 400px;
  overflow: hidden;
  background: @carousel-bg;

  &-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &-slider {
    position: relative;
    left: 0;
    height: 100%;
    transition: transform @carousel-animation-duration ease;
    will-change: transform;

    &-item {
      background: @carousel-bg;
      float: left;
      height: 100%;
      width: 100%;
    }
  }

  &-slider-after {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: @carousel-bg;
    top: 0;
    animation: moveLeftHalf @carousel-animation-duration ease forwards;

    &-vertical {
      animation: moveLeftHalf-vertical @carousel-animation-duration ease forwards;
    }
  }

  &-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    > ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &-label-wrapper {
    margin: @carousel-handler-margin;

    input {
      width: 0;
      height: 0;
      position: absolute;
      opacity: 0;
    }

    input:checked ~ label {
      background: @carousel-handler-active-bg;
    }
  }

  &-label {
    cursor: pointer;
    display: block;
    background: @carousel-handler-bg;
    transition: @carousel-handler-transition;
    transition-property: background, width, height;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -1 * @carousel-handler-sense-width;
      right: -1 * @carousel-handler-sense-width;
      bottom: -1 * @carousel-handler-sense-width;
      left: -1 * @carousel-handler-sense-width;
    }

    &:hover {
      background: @carousel-handler-hover-bg;
    }
  }

  // Handler Shape
  &-shape-dot &-label {
    width: @carousel-dot-side-length;
    height: @carousel-dot-side-length;
    border-radius: 50%;
  }

  &-shape-bar &-label {
    border-radius: (@carousel-bar-height / 2);
  }

  &-placement-top&-shape-bar &-label,
  &-placement-bottom&-shape-bar &-label {
    width: @carousel-bar-width;
    height: @carousel-bar-height;

    &-wrapper input:checked ~ label {
      width: @carousel-bar-active-width;
    }
  }

  &-placement-left&-shape-bar &-label,
  &-placement-right&-shape-bar &-label {
    width: @carousel-bar-height;
    height: @carousel-bar-width;

    &-wrapper input:checked ~ label {
      height: @carousel-bar-active-width;
    }
  }

  // Placement

  &-placement-top &-toolbar,
  &-placement-bottom &-toolbar {
    left: 0;
    width: 100%;

    > ul {
      align-items: center;
      justify-content: center;
    }
  }

  &-placement-top &-toolbar {
    top: @carousel-toolbar-wrapper-margin;
  }

  &-placement-bottom &-toolbar {
    bottom: @carousel-toolbar-wrapper-margin;
  }

  &-placement-left &-toolbar,
  &-placement-right &-toolbar {
    top: 0;
    width: @carousel-dot-wrapper-side-length;
    height: 100%;

    > ul {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &-placement-left &-toolbar {
    left: @carousel-toolbar-wrapper-margin;
  }

  &-placement-right &-toolbar {
    right: @carousel-toolbar-wrapper-margin;
  }
}
