@import '../../styles/common';
@import 'mixin';

// Form

.@{ns}form-inline {
  > *,
  > .@{ns}btn {
    margin-right: 20px;
    margin-bottom: 24px;
    vertical-align: top;
  }

  .rs-control-label {
    vertical-align: top;
    margin-bottom: auto;
    margin-right: @control-label-margin-right;
    margin-top: @padding-base-vertical;
    display: inline-block;
  }
}
