@import '../../styles/common';
@import '../../Picker/styles/mixin';
@import '../../TreePicker/styles/mixin';

// Check Tree Picker
// ----------------------

// Check tree picker name space
@ctpns: ~'@{ns}@{check-tree-picker-prefix}';

.@{ctpns} {
  background-color: @picker-tree-bg;
  max-height: 360px;
  height: 100%;
  overflow-y: auto;

  /* stylelint-disable-next-line */ // This class name provide by Virtualized.
  .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }
}

.@{ctpns}-node {
  position: relative;
  font-size: @picker-tree-node-font-size;
  line-height: @picker-tree-node-line-height;

  > .@{ctpns}-node-label {
    .@{ns}check-item {
      display: inline-block;

      .@{ns}picker-menu & {
        display: block;
      }
    }

    &:focus .@{ns}check-item .@{ns}checkbox-checker > label {
      .picker-item-hover();
    }

    .@{ns}check-item.@{ns}checkbox-checked .@{ns}checkbox-checker > label {
      .picker-item-active();
    }

    .@{ns}check-item .@{ns}checkbox-checker > label {
      text-align: left;
      position: relative;
      margin: 0;
      //padding-left: 16px;
      padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
      //text gap + checkbox space
      padding-left: 58px; // 10px + 36px + 12px

      &::before {
        content: '';
        position: absolute;
        width: (@picker-tree-arrow-down-width+ @picker-tree-arrow-down-gap + 30px); // checkbox-spacing = 30
        height: 100%;
        background-color: @picker-tree-bg;
        top: 0;
        margin-left: -58px; // 10px + 36px + 12px
      }

      .@{ns}checkbox-wrapper {
        left: @checkbox-sense-width + 10px;
      }

      // Only has the first level
      .without-children & {
        padding-left: 34px; //text gap + checkbox space

        &::before {
          width: 28px;
          margin-left: -36px;
        }

        .@{ns}checkbox-wrapper {
          left: 0;
        }
      }
    }
  }

  // Uncheckable state
  &-all-uncheckable > .@{ctpns}-node-label {
    .@{ns}check-item .@{ns}checkbox-checker > label {
      padding-left: 22px; // 10px + 12px

      &::before {
        width: 14px;
        margin-left: 0;
        left: 0;
      }
    }
  }
}

// expand icon
.@{ctpns}-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .@{ctpns}-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-8px) translateY(-10px) */;
    transform-origin: 3.5px 16px;
    margin-left: -2px;
    margin-top: 2px;
    font-family: @font-family-icon;
    font-style: normal;
    user-select: none;

    &::before {
      content: @picker-tree-arrow-down;
    }

    // expand children tree
    .@{ctpns}-open > .@{ctpns}-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .@{ctpns}-node-expanded {
    transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }

  > .@{ctpns}-node-custom-icon {
    .node-item-prepend-icon();
  }
}

// node children
.@{ctpns}-node-children {
  > .@{ctpns}-children {
    display: none;

    .@{ctpns}-open& {
      display: block;
    }
  }
}

.@{ns}picker-menu {
  &.@{ctpns}-menu {
    padding-top: @picker-menu-padding;

    .@{ns}picker-search-bar {
      padding-top: 0;
    }
  }

  .@{ctpns} {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &-node > .@{ctpns}-node-label .@{ctpns}-node-text-wrapper {
      .ellipsis();

      display: inline-block;
      vertical-align: top;
    }
  }
}

/* rtl:begin:ignore */

[dir='rtl'] .@{ctpns}-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
}

/* stylelint-disable-next-line */
.@{ctpns} {
  /* stylelint-disable-next-line */
  &-node > &-node-label .@{ns}check-item .@{ns}checkbox-checker > label::before {
    /* stylelint-disable-next-line */
    [dir='rtl'] .ReactVirtualized__Grid__innerScrollContainer & {
      padding-right: inherit;
      right: 46px;
    }
  }
}

/* rtl:end:ignore */
