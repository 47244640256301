@import '../../styles/common';
@import '../../Picker/styles/mixin';
@import '../../Dropdown/styles/mixin';

// Cascader
// ----------------------

// Cascader Picker Name Space
@cspns: ~'@{ns}@{cascader-picker-prefix}';

.@{cspns}-menu-items > div {
  .clearfix();
}

// Search panel
.@{cspns}-search-panel {
  max-height: @cascader-search-panel-max-height;
  overflow: auto;
}

.@{cspns}-row {
  cursor: pointer;
  padding-left: @cascader-row-content-padding-horizontal;
  padding-right: @cascader-row-content-padding-horizontal;

  &:hover {
    color: @cascader-search-panel-hover-color;
    background-color: @cascader-search-panel-hover-bg;
  }
}

.@{cspns}-row-disabled {
  cursor: not-allowed;

  &,
  &:hover {
    color: @B400;
  }

  strong {
    opacity: 0.7;
  }
}

.@{cspns}-col {
  padding: @picker-item-content-padding-vertical 0;
  display: inline-block;

  &::after {
    content: '\00a0/\00a0'; //content:' / '
  }

  &:last-child::after {
    display: none;
  }

  strong {
    color: @cascader-search-panel-highlight-word-color;
  }
}

// Column menu
.@{cspns}-menu-column {
  float: left;
  overflow-y: auto;
}

// Menu Items
.@{cspns}-menu-items {
  padding: @border-radius-base 0;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

// Menu item
.@{cspns}-menu-item {
  @padding-right: 16px + @picker-item-content-padding-horizontal;

  position: relative;
  display: block;
  padding: @picker-item-content-padding-vertical @padding-right
    @picker-item-content-padding-vertical @picker-item-content-padding-horizontal;
  transition: @picker-item-transition;
  cursor: pointer;
  text-decoration: none;
  color: @picker-select-item-color;
  word-break: break-word;

  // Has children
  &s-has-children & {
    padding-right: @dropdown-toggle-padding-right;
  }

  &:not(&-disabled):hover,
  &:not(&-disabled):focus,
  &.@{ns}picker-select-menu-item-focus {
    .picker-item-hover();

    text-decoration: none;
  }

  &&-focus,
  &&-active {
    .picker-item-active();

    &,
    &:hover,
    &:focus,
    &:hover:focus {
      background-color: @picker-select-menu-item-selected-bg;
    }

    .@{cspns}-menu-caret {
      color: @text-color;
    }
  }

  &&-active {
    &,
    &:hover,
    &:focus,
    &:hover:focus {
      color: @picker-select-menu-item-selected-color;
    }
  }

  &&-disabled {
    color: @picker-menu-item-disabled-color;
    text-decoration: none;
    cursor: @cursor-disabled;
  }

  &&-active&-disabled {
    &,
    &:hover {
      color: @picker-select-menu-item-selected-disabled-color;
    }
  }
}

.@{cspns}-menu-caret {
  .dropdown-toggle-caret-common();

  &::before {
    content: @drop-right-caret-content ~'/* rtl: "@{drop-left-caret-content}" */';
  }
}
