@import '../../styles/common';
@import '../../Form/styles/mixin';
@import '../../Picker/styles/mixin';

.@{tag-picker} {
  &:not(.@{ns}picker-disabled) .@{ns}picker-toggle {
    // Make sure align with input
    left: 0;
    cursor: text;
  }

  &.@{ns}picker-disabled {
    cursor: not-allowed;

    .@{ns}picker-toggle {
      position: absolute;
    }
  }

  .@{ns}tag {
    max-width: ~'calc(100% - @{tag-picker-content-padding-horizontal})';
    vertical-align: top;
    .ellipsis-basic();
  }

  .@{ns}picker-search-input,
  .@{ns}tag {
    margin-top: @tag-picker-content-padding-vertical;
  }

  &.@{ns}picker-has-value .@{ns}picker-search-input {
    padding-left: @tag-picker-content-padding-horizontal;
  }

  .@{ns}tag,
  .@{ns}tag + .@{ns}tag {
    margin-left: @tag-picker-content-padding-horizontal;
  }

  .@{ns}picker-tag-wrapper {
    position: relative;
    // make sure tag in front of toggle
    z-index: @zindex-picker-toggle + 1;
    padding-bottom: @tag-picker-content-padding-vertical;
  }

  .@{ns}picker-search {
    &,
    &-input,
    &-input > input {
      display: inline-block;
      width: auto;
      min-width: 14px;
    }

    &-input > input {
      padding: 0;
    }
  }
}
