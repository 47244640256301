@import '../../styles/common';
@import '../../Checkbox/styles/mixin';
@import '../../Button/styles/mixin';
@import 'mixin';

.@{ns}radio {
  .radio-checkbox-common();

  &-group-inline {
    display: inline-block;
    margin-left: -1 * @radio-sense-width;
  }

  &-group-picker {
    color: @picker-default-toggle-font-color;
    border: 1px solid @picker-default-toggle-border-color;
    border-radius: @border-radius-base;
    margin-left: 0;

    .@{ns}radio-inline {
      margin-left: 0;
    }
  }

  // Reset checker padding value.
  &-group-picker &-checker {
    padding: 0;
    min-height: auto;
  }

  // Picker Radio group hidden radio.
  &-group-picker &-wrapper {
    display: none;
  }

  &-group-picker &-checker > label {
    .btn(base);

    color: @radio-label-font-color;
    transition: color 0.3s linear;
    padding: @padding-base-vertical - 1px 10px;

    &:hover,
    &:active {
      color: @radio-label-font-hover-color;
    }
  }

  &-group-picker &-checked &-checker > label {
    color: @radio-picker-active-color;
  }

  &-group-picker &-disabled &-checker > label {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &-group-picker &-disabled:not(&-checked) &-checker > label {
    color: @radio-label-font-color;
  }
}

.@{ns}radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: (@radio-sense-width * 2 + @radio-width-height);
  min-height: (@radio-sense-width * 2 + @radio-width-height);
  line-height: 1;
  position: relative;
}

.@{ns}radio-wrapper {
  position: absolute;
  width: @radio-width-height;
  height: @radio-width-height;
  display: inline-block;
  left: @radio-sense-width;
  top: @checkbox-sense-width;

  [type='radio'] {
    opacity: 0;
  }

  &::before,
  &::after,
  .@{ns}radio-inner::before,
  .@{ns}radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .@{ns}radio-inner::before,
  .@{ns}radio-inner::after {
    width: @radio-width-height;
    height: @radio-width-height;
  }

  // Ripple effect area.
  &::before {
    .radio-inner-wrapper(@radio-checked-border-color);

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .@{ns}radio-checked & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sense area(Can be clicked).
  &::after {
    top: -@radio-sense-width;
    right: -@radio-sense-width;
    bottom: -@radio-sense-width;
    left: -@radio-sense-width;
  }

  // Out border.
  .@{ns}radio-inner::before {
    .radio-inner-wrapper(@radio-default-border-color);

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .@{ns}radio-checker:hover & {
      border-color: @radio-hover-border-color;
    }

    .@{ns}radio-checked & {
      border-color: @radio-checked-border-color;
      background-color: @radio-checked-border-color;
    }

    .@{ns}radio-disabled:not(.@{ns}radio-checked) & {
      border-color: @radio-disabled-color;
      background-color: @radio-disabled-color;
    }

    .@{ns}radio-disabled.@{ns}radio-checked & {
      opacity: 0.3;
    }
  }

  // Solid circle.
  .@{ns}radio-inner::after {
    width: @radio-inner-width-height;
    height: @radio-inner-width-height;
    background: @radio-inner-checked-color;
    margin-top: ((@radio-width-height - @radio-inner-width-height) / 2);
    margin-left: ((@radio-width-height - @radio-inner-width-height) / 2);
    border-radius: @radio-border-radius;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .@{ns}radio-checked & {
      transform: scale(1);
      opacity: 1;
    }
  }

  [type='radio']:focus ~ .@{ns}radio-inner::before {
    .radio-inner-active-shadow(@radio-default-border-color);

    @hover: ~':hover';

    @{hover}& {
      .radio-inner-active-shadow(@radio-checked-border-color);
    }

    .@{ns}radio-checked & {
      .radio-inner-active-shadow(@radio-checked-border-color);
    }
  }
}
