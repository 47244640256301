@import '../../styles/common';
@import 'mixin';

//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.@{alert-ns} {
  position: fixed;
  z-index: @zindex-notification;
  // Make sure the mouse can penetrate through the gap area.
  pointer-events: none;

  &-item {
    // Recovery mouse event.
    pointer-events: auto;
  }

  // Multiple notification should have spacing
  // Hiding notification haven't spacing.
  &-item-wrapper:not(.@{alert-ns}-fade-exited) + .@{alert-ns}-item-wrapper {
    margin-top: 10px;
  }

  // Center content
  &-container {
    left: 50%;
  }

  // Alert wrapper
  &-item-wrapper {
    display: block;
    text-align: center;
    width: 100%;
    margin-left: -50%;

    .@{alert-ns}-item {
      display: inline-block;
      position: relative;
    }
  }

  // Alert content
  &-item-content {
    text-align: left;
    padding: @alert-padding;
    border-radius: @alert-border-radius;
    box-shadow: @alert-box-shadow;
    max-width: @alert-max-width;
    padding-left: 42px;
    padding-right: 46px;
    position: relative;

    p {
      margin-bottom: 0;
    }

    .@{ns}icon {
      position: absolute;
      // Make sure icon vertical align center
      top: @alert-padding + 3px;
      left: @alert-padding;
      margin-right: 10px;
      font-size: @font-size-small;
      line-height: @font-size-small;
    }
  }

  // Alert close button
  &-item-close {
    position: absolute;
    top: 20px;
    right: 16px;
    padding: @alert-close-btn-padding;
    font-size: @alert-close-btn-size;
    line-height: 1;
    outline: none !important;
    cursor: pointer;

    &-x::before {
      .icon-font();

      content: @default-close-btn-content;
    }
  }

  // Animations
  &-fade-exited,
  &-fade-entering {
    opacity: 0;
  }

  // The same to @keyframe alertMoveOut  100%.
  &-fade-exited {
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }

  &-fade-entered {
    animation: alertMoverIn 0.3s ease-in forwards;
  }

  &-fade-leave-active {
    animation: alertMoveOut 0.3s ease-out forwards;
  }
}

// Alternate styles
// -------------------------
// Generate contextual modifier classes for colorizing the alert.

.@{alert-ns}-success {
  .alert-variant(@alert-success-bg; @alert-success-icon-color);
}

.@{alert-ns}-info {
  .alert-variant(@alert-info-bg; @alert-info-icon-color);
}

.@{alert-ns}-warning {
  .alert-variant(@alert-warning-bg; @alert-warning-icon-color);
}

.@{alert-ns}-error {
  .alert-variant(@alert-error-bg; @alert-error-icon-color);
}
