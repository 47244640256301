@import '../../styles/common';
@import '../../Form/styles/mixin';

//
// Forms
// --------------------------------------------------

// Common form controls
.@{ns}input {
  .default-input();

  // Reset height for `textarea`
  textarea& {
    overflow: auto;
    resize: vertical;
  }
}

// Form control sizing
.@{ns}input-xs {
  .input-xs();
}

.@{ns}input-lg {
  .input-lg();
}

.@{ns}input-sm {
  .input-sm();
}
