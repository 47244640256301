.pagination-active(@font-color) {
  color: @font-color;
  background-color: transparent;

  &:hover,
  &:focus,
  &.focus {
    color: @font-color;
    background-color: transparent;
  }

  &:active {
    &,
    &:hover,
    &:focus,
    &.focus {
      color: @font-color;
      background-color: transparent;
    }
  }
}
