@import '../../styles/common';
@import '../../Picker/styles/mixin';
@import '../../Dropdown/styles/mixin';

// MultiCascader
// ----------------------

// Cascader Picker Name Space
@cspns: ~'@{ns}@{cascader-picker-prefix}';
@cmpns: ~'@{ns}@{cascader-multi-picker-prefix}';

.@{cspns} {
  &-menu .@{ns}check-item .@{ns}checkbox-checker > label {
    padding-right: 26px; // 12px + 8px + 6px
  }

  &-menu .@{ns}check-item.@{ns}check-item-focus,
  &-menu .@{ns}check-item.@{ns}checkbox-checked {
    .@{ns}checkbox-checker > label {
      .picker-item-active();
    }
  }

  &-menu-column&-menu-column-uncheckable {
    .@{ns}check-item .@{ns}checkbox-checker > label {
      padding-left: @picker-item-content-padding-horizontal;
    }
  }
}

.@{cmpns}-menu .@{cspns}-col {
  padding: 0;
}

.@{cmpns}-menu .@{cspns}-row {
  padding-left: 0;
}
